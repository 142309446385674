.gpt3__header {
    display: flex;
    padding: 1rem 0rem 0rem 6rem;
}



.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.gpt3__header-content h1 {
    font-weight: 800;
    font-size: 58px;
    line-height: 65px;

    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    border-radius: 5px 0px 0px 5px;
    font-size: 20px;
    line-height: 27px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    outline: none;
    padding: 0 1rem;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-transform: capitalize;
}

.gpt3__header-content__input button {
    flex: .36;
    width: 100%;
    /* background: rgb(96, 96, 184); */
    background: rgba(255, 95, 37, 0.911);
    min-height: 50px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #FFF;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
    text-transform: capitalize;
    box-shadow: 4px 5px 7px 0px rgb(8, 41, 141);
}

.gpt3__header-content__input button:hover {
    box-shadow: none;
}


.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin-left: 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #FFF;
    text-align: center;
    margin: 0 0 0 1rem;
}

.gpt3__header-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
    animation: fade-in-fwd 1s cubic-bezier(.39, .575, .565, 1.000) both;
    animation-delay: 1s;

}

@keyframes fade-in-fwd {
    0% {
        transform: translateZ(-80px);
        opacity: 0
    }

    100% {
        transform: translateZ(0);
        opacity: 1
    }
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        padding: 4rem 6rem;
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}


@media screen and (max-width: 650px) {
    .gpt3__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}


@media screen and (max-width: 490px) {

    .gpt3__header {
        padding: 2rem 2rem;
    }

    .gpt3__header-content__people img {
        width: 101px;
        height: 20px;
    }

    .gpt3__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
        flex: 1;
    }


}