.gpt3__possibility {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gpt3__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}


.gpt3__possibility-content h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;

    color: #71E5FF;;
}

.gpt3__possibility-content h1 {
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

    margin: 1rem 0;
}

.gpt3__possibility-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    color: var(--color-text);
}

.gpt3__possibility-content h4:last-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;

    margin-top: 1rem;
    color: var(--color-subtext);
}

@media screen and (max-width: 910px){
    .gpt3__possibility{
        flex-direction: column;
    }

    .gpt3__possibility-content{
        margin-top: 2rem;
    }

    .gpt3__possibility-content h1{
        font-size: 28px;
        line-height: 30px;
    }

    .gpt3__possibility-content p{
        font-size: 15px;
        line-height: 25px;
    }

    .gpt3__possibility-content h4{
        font-size: 14px;
    }

    .gpt3__possibility-content h4:last-child{
        font-size: 14px;
    }
}


@media screen and (max-width: 550px){
    .gpt3__possibility{
        flex-direction: column;
    }

    .gpt3__possibility-content{
        margin-top: 2rem;
    }

    .gpt3__possibility-content h1{
        font-size: 20px;
        line-height: 25px;
    }

    .gpt3__possibility-content p{
        font-size: 12px;
        line-height: 16px;
    }

    .gpt3__possibility-content h4{
        font-size: 10px;
    }

    .gpt3__possibility-content h4:last-child{
        font-size: 10px;
    }
}